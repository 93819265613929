import React from 'react';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Layout } from '@components';

const StyledTalkContainer = styled.main`
  max-width: 1000px;
`;
const StyledTalkHeader = styled.header`
  margin-bottom: 50px;
  .tag {
    margin-right: 10px;
  }
`;
const StyledTalkContent = styled.div`
  
  font-size: var(--fz-xxl);

  margin-bottom: 100px;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 2em 0 1em;
  }

  p {
    margin: 1em 0;
    line-height: 1.5;
    color: var(--light-slate);
  }

  a {
    ${({ theme }) => theme.mixins.inlineLink};
  }

  code {
    background-color: var(--lightest-navy);
    color: var(--lightest-slate);
    border-radius: var(--border-radius);
    font-size: var(--fz-md);
    padding: 0.2em 0.4em;
  }

  pre code {
    background-color: transparent;
    padding: 0;
  }

  
`;

const TalkTemplate = ({ data, location }) => {
  const { frontmatter, html } = data.talks.edges[0].node;
  const {  talkDate, talkTitle, talkLocation } = frontmatter;

  return (
    <Layout location={location}>
      <Helmet title={talkTitle} />

      <StyledTalkContainer>
        <span className="breadcrumb">
          <span className="arrow">&larr;</span>
          <Link to="/talks">All Talks</Link>
        </span>

        <StyledTalkHeader>
          <h1 className="medium-heading">{talkTitle}</h1>
          <p className="subtitle">
            <p>{talkDate}</p>
            <span>&nbsp;&mdash;&nbsp;</span>
            <p>{talkLocation}</p>
            {/* {tags &&
              tags.length > 0 &&
              tags.map((tag, i) => (
                <Link key={i} to={`/pensieve/tags/${kebabCase(tag)}/`} className="tag">
                  #{tag}
                </Link>
              ))} */}
          </p>
        </StyledTalkHeader>

        <StyledTalkContent dangerouslySetInnerHTML={{ __html: html }} />
      </StyledTalkContainer>
    </Layout>
  );
};

export default TalkTemplate;

TalkTemplate.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

export const pageQuery = graphql`
query($talkSlug: String!){
    talks: allMarkdownRemark(
      filter: {fileAbsolutePath: {regex: "/content/talks/"}, frontmatter: {talkSlug: {eq: $talkSlug},showInTalks: {ne: false}}}
      sort: {fields: [frontmatter___talkPosition], order: DESC}
    ) {
      edges {
        node {
          frontmatter {
            talkSlug
            talkYoutubeVideoId
            talkTitle
            talkPosition
            talkImage
            talkDate
            showInTalks
            talkLocation
          }
          html
        }
      }
    }
  }
  
`;
